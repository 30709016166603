.dropdown-toggle {
  align-items: center;
  display: flex;

  &::after {
    display: none;
  }
}

.expanded .container {
  max-width: 100%;
}
