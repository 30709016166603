// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

:root {
  --cyan: #05adb4;
  --blue: #0a0946;
  --purple: #a31b69;
  --yellow: #ffc901;
  --orange: #ea6c25;
  --red: #c72333;
  --black: #212121;
  --cape-cod: #353636;
  --silver: #a5a5a5;
  --regent-gray: #89919b;
  --iron: #c9cdd1;
  --alto: #d4d4d4;
  --seashell: #f1f1f1;
  --albaster: #f7f7f7;
  --athens-gray: #f8f9fa;
  --white: #ffffff;
  --primary: #05adb4;
  --secondary: #0a0946;
  --warning: #ea6c25;
  --danger: #c72333;
}

html,
body {
  height: 100%;
}

body {
  background-color: $athens-gray;
  color: $black;
  font-size: 12px;
  margin: 0;
}

b,
strong {
  font-family: "NotoSansBold", sans-serif !important;
}

a {
  color: $cyan;
  cursor: pointer;
  text-decoration: none !important;
  &:hover {
    color: $cyan;
    text-decoration: underline !important;
  }
}

table {
  border-collapse: collapse;
  table-layout: auto;
  width: 100%;
  border-spacing: 0;

  tr {
    min-height: 3.5rem;
  }

  th {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: rgba(0, 0, 0, 0.12);
    padding: 1rem;
    text-align: left;
    line-height: 1.25rem;
    font-size: 12.5px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.54);
  }

  td {
    padding: 1rem;
  }

  a {
    color: $blue;
    text-decoration: underline;
  }
}

::-webkit-scrollbar {
  background-color: #f5f5f5;
  height: 8px;
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #dee2e6;
}
