// -----------------------------------------------------------------------------
// This file contains loader styles.
// -----------------------------------------------------------------------------

.bar-loader {
  @include basic-loader;
  border-radius: 2px;
  height: 14px;
  margin-top: 4px;
  margin-bottom: 4px;
  width: 100%;

  &.title {
    width: 25%;
  }
  &.content-large {
    width: 30%;
  }
  &.subtitle {
    width: 20%;
  }
  &.detail {
    width: 92px !important;
  }
  &.dark {
    background: $alto;
    animation: none;
  }

  &.form-item {
    border-radius: 0.3rem;
    height: 3.18rem;
  }

  &.form-button {
    border-radius: 0.3rem;
    height: 2rem;
    width: 80%;
  }

  &.h2 {
    height: 2rem;
  }
}
